export const softwareVersionNumber = "1.0.0";

export const firebaseConfig = {
   apiKey: "AIzaSyAl5K-NIwaTO-GY9lyhrE3XJI500yJqHqQ",
   authDomain: "kriss-vv.firebaseapp.com",
   projectId: "kriss-vv",
   storageBucket: "kriss-vv.appspot.com",
   messagingSenderId: "848683442315",
   appId: "1:848683442315:web:d039ba9893010cd125f48e",
   measurementId: "G-VK571WBNB6"
};